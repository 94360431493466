import { config } from '@abyss/web/tools/config';

/**
 * msalConfig
 *
 * Necessary configuration to handle authentication.
 *
 * @type {{auth: {redirectUri, postLogoutRedirectUri, clientId, navigateToLoginRequestUrl: boolean, authority: string},
 *   cacheLocation: string, storeAuthStateInCookie: boolean}}
 */
export const msalConfig = {
  auth: {
    clientId: config('NEXT_PUBLIC_AUTH_CLIENT_ID'),
    authority: `https://login.microsoftonline.com/${config('NEXT_PUBLIC_AUTH_TENANT_ID')}`,
    redirectUri: config('NEXT_PUBLIC_AUTH_REDIRECT_URI'),
    postLogoutRedirectUri: config('NEXT_PUBLIC_AUTH_LOGOUT_URI'),
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

/**
 * loginRequest
 *
 * Add here scopes for id token to be used at MS Identity Platform endpoints.
 *
 * @type {{scopes: *[]}}
 */
export const loginRequest = {
  scopes: [
    ...(config('NEXT_PUBLIC_AUTH_SCOPES')?.split('|') ?? []),
    ...(config('NEXT_PUBLIC_AUTH_TOKEN_SCOPES')?.split('|') ?? []),
  ],
};

/**
 * graphConfig
 *
 * Add here scopes for id token to be used at MS Identity Platform endpoints.
 *
 * @type {{graphMeEndpoint: string}}
 */
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button } from '@src/components/Button';
import { Divider } from '@abyss/web/ui/Divider';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { isEmpty, isUndefined } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Loader } from '@src/components/Loader';
import { Modal } from '@abyss/web/ui/Modal';
import { useApi } from '@src/context/Api';
import { useForm } from '@abyss/web/hooks/useForm';
import { useToast } from '@abyss/web/hooks/useToast';
import { TagSelectionField } from '@src/common/fields/TagSelection';

/**
 * AssignTags
 *
 * Displays a modal to assign action paths to the risk record.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const AssignTags = (props) => {
  const { eid, isOpen, setIsOpen, handleSearch, recordTags } = props;

  const [currentTags, setCurrentTags] = useState([]);
  const [addedTags, setAddedTags] = useState([]);
  const [removedTags, setRemovedTags] = useState([]);

  const { useApiQuery, useApiMutation, clearApiCache } = useApi();
  const [ListTags, { data: tags, isLoading, isFetching }] = useApiQuery('ListTags');
  const [RiskRecordTagAssignment] = useApiMutation('RiskRecordTagAssignment', {
    excludedHttpCodes: [400],
  });
  const [RiskRecordTagUnassignment] = useApiMutation('RiskRecordTagUnassignment', {
    excludedHttpCodes: [400],
  });

  const defaultValues = {
    tags: [],
  };

  const form = useForm({ defaultValues });
  const { toast } = useToast();

  /**
   * Retrieve the list of user defined tags
   */
  useEffect(() => {
    if (isUndefined(tags)) {
      ListTags({
        page: 0,
        size: 9999,
        sort: 'code,asc',
        userDefined: true,
      });
    }
  }, [tags]);

  useEffect(() => {
    if (!isUndefined(tags)) {
      const theTags = tags?.content
        ?.filter((tag) => {
          return recordTags?.find((recordTag) => {
            return recordTag === tag?.tag;
          });
        })
        .map((tag) => {
          return tag.tag;
        });

      setCurrentTags(theTags);

      form?.reset(
        {
          tags: theTags,
        },
        {
          keepDirty: false,
          keepDirtyValues: false,
          keepErrors: false,
          keepIsValid: false,
          keepSubmitCount: true,
          keepTouched: false,
          keepValues: false,
        }
      );
    }
  }, [tags]);

  /**
   * handleSubmit
   *
   * Handles the form submission.
   *
   * @returns {Promise<boolean>}
   */
  const handleSubmit = async () => {
    try {
      if (!form?.formState?.isDirty) {
        return false;
      }

      if (!isEmpty(removedTags)) {
        const toastId = `tag-unassignment-${eid}`;
        toast.show({
          id: `${toastId}-info`,
          title: 'Unassigning Tags from EID',
          message: 'Unassigning Tags from EID...',
          isLoading: true,
          ariaLoadingLabel: 'Unassigning Tags from EID',
          variant: 'info',
          autoClose: false,
        });

        const ununassignments = removedTags?.map(async (tag) => {
          const payload = {
            eid,
            tagCode: tag,
          };

          // eslint-disable-next-line @typescript-eslint/return-await
          return await RiskRecordTagUnassignment(payload, {
            onSuccess: () => {
              toast.hide(`${toastId}-info`);
              toast.show({
                id: `${toastId}-success`,
                title: 'Unassigned Tags from EID',
                message: 'Tags have been successfully unassigned.',
                variant: 'success',
              });
            },
            onError: () => {
              toast.hide(`${toastId}-info`);
              toast.show({
                id: `${toastId}-error`,
                title: 'Unassigning Tags Failed',
                message: 'Unable from unassign Tags.',
                variant: 'error',
              });
            },
          });
        });
        await Promise.all(ununassignments);
      }

      if (!isEmpty(addedTags)) {
        const toastId = `tag-assignment-${eid}`;
        toast.show({
          id: `${toastId}-info`,
          title: 'Assigning Tags to EID',
          message: 'Assigning Tags to EID...',
          isLoading: true,
          ariaLoadingLabel: 'Assigning Tags to EID',
          variant: 'info',
          autoClose: false,
        });

        const assignments = addedTags?.map(async (tag) => {
          const payload = {
            eid,
            tagCode: tag,
          };

          // eslint-disable-next-line @typescript-eslint/return-await
          return await RiskRecordTagAssignment(payload, {
            onSuccess: () => {
              toast.hide(`${toastId}-info`);
              toast.show({
                id: `${toastId}-success`,
                title: 'Assigned Tags to EID',
                message: 'Tags have been successfully assigned.',
                variant: 'success',
              });
            },
            onError: () => {
              toast.hide(`${toastId}-info`);
              toast.show({
                id: `${toastId}-error`,
                title: 'Assigning Tags Failed',
                message: 'Unable to assign Tags.',
                variant: 'error',
              });
            },
          });
        });

        await Promise.all(assignments);
      }

      setIsOpen(false);
      clearApiCache(['GetRiskRecord']);
      handleSearch();
    } catch (error) {
      console.error('Error in handleSubmit', error);
    }

    return true;
  };

  /**
   * handleChange
   *
   * Handles the change event for the tags.
   *
   * @param values
   */
  const handleChange = (values) => {
    setAddedTags(
      values.filter((item) => {
        return !currentTags?.includes(item);
      })
    );
    setRemovedTags(
      currentTags?.filter((item) => {
        return !values.includes(item);
      })
    );
  };

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Header/components/Details/components/AssignTags/AssignTags.jsx">
      <Modal
        title="Assign Tags"
        isOpen={isOpen}
        onClose={() => {
          return setIsOpen(false);
        }}
      >
        <FormProvider state={form} autoComplete="off" onSubmit={handleSubmit}>
          <Modal.Section>
            <Grid>
              {isLoading || isFetching || isUndefined(tags) ? (
                <Grid.Col
                  span={{
                    xs: '100%',
                  }}
                >
                  <Loader width="100%" height="100%" />
                </Grid.Col>
              ) : (
                <Grid.Col
                  span={{
                    xs: '50%',
                  }}
                >
                  <TagSelectionField
                    form={form}
                    handleChange={handleChange}
                    isRequired={false}
                    systemDefinedTags={false}
                  />
                </Grid.Col>
              )}
            </Grid>
          </Modal.Section>
          <Modal.Section>
            <Divider height={1} />
            <Layout.Group alignLayout="right">
              <Button
                variant="outline"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button variant="solid" type="submit" isDisabled={!form?.formState?.isDirty}>
                Assign
              </Button>
            </Layout.Group>
          </Modal.Section>
        </FormProvider>
      </Modal>
    </ErrorHandler>
  );
};

AssignTags.propTypes = {
  eid: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  handleSearch: PropTypes.func,
  recordTags: PropTypes.arrayOf(PropTypes.string),
};

AssignTags.defaultProps = {
  eid: '',
  isOpen: false,
  setIsOpen: () => {},
  handleSearch: () => {},
  recordTags: [],
};

import PropTypes from 'prop-types';
import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { useFormFieldArray } from '@abyss/web/hooks/useFormFieldArray';
import { Styles } from './includes/styles';
import { Table } from './components/Table';

/**
 * RepeatableFields
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const RepeatableFields = (props) => {
  const { accessor, disabledFields, fields, form, defaultValues } = props;

  const {
    fields: repeatableFields,
    append,
    remove,
  } = useFormFieldArray({
    control: form.control,
    name: accessor,
  });

  const values = form?.getValues(accessor);

  return (
    <ErrorHandler location="src/components/RepeatableFields/RepeatableFields.jsx">
      <Styles>
        <Grid>
          <Grid.Col span={{ xs: '100%' }}>
            <Layout.Group alignLayout="right">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                variant="custom"
                id="addRow"
                onClick={() => {
                  append(defaultValues);
                }}
              >
                <Flex alignItems="center">
                  <div>
                    <IconSymbol icon="add_circle" variant="filled" />
                    <IconSymbol icon="add_circle" variant="outlined" />
                  </div>
                  <div>Add Row</div>
                </Flex>
              </Link>
            </Layout.Group>
            <Table
              {...{
                accessor,
                disabledFields,
                fields,
                remove,
                repeatableFields,
                rows: values,
              }}
            />
          </Grid.Col>
        </Grid>
      </Styles>
    </ErrorHandler>
  );
};

RepeatableFields.propTypes = {
  accessor: PropTypes.string,
  disabledFields: PropTypes.shape({}),
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  form: PropTypes.shape({
    control: PropTypes.shape({
      fields: PropTypes.shape({
        append: PropTypes.func,
        remove: PropTypes.func,
      }),
    }),
    getValues: PropTypes.func,
  }),
  defaultValues: PropTypes.arrayOf(PropTypes.shape({})),
};

RepeatableFields.defaultProps = {
  accessor: '',
  disabledFields: {},
  fields: [],
  form: {
    control: {
      fields: {
        append: () => {},
        remove: () => {},
      },
    },
    getValues: () => {},
  },
  defaultValues: [],
};

import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isEmpty, isUndefined } from 'lodash';

/**
 * SaveAlertConfiguration
 *
 * Saves a single alert with the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<{}>}
 * @constructor
 */
export const SaveAlertConfiguration = async (payload = {}) => {
  try {
    const theMutationKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'POST',
      headers: {
        'x-api-endpoint': '/alerts/configuration',
      },
      params: { mutationKey: theMutationKey },
      data: {},
    };

    if (!isUndefined(thePayload?.configurationId) && !isEmpty(thePayload?.configurationId)) {
      requestArgs.method = 'PUT';
      requestArgs.headers['x-api-endpoint'] += `/${thePayload?.configurationId}`;
    }

    Object.keys(thePayload).forEach((key) => {
      const value = thePayload?.[key];

      if (key === 'configurationId' && isEmpty(value)) {
        return;
      }

      requestArgs.data[key] = value;
    });

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/mutations/SaveAlertConfiguration.js -> SaveAlertConfiguration() -> error:', theError);

    throw error;
  }
};

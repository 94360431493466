import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty, isNil, isUndefined } from 'lodash';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useRoutesContext } from '@src/context/Routes';
import { useScrollTrigger } from '@abyss/web/hooks/useScrollTrigger';
import { Layout } from './components/Layout';
import { useAssets } from './hooks/useAssets';
import { useSearch } from './hooks/useSearch';

/**
 * EidSearch
 *
 * Find additional information about a specific Risk Record.
 *
 * @returns {Element}
 * @constructor
 */
export const EidSearch = () => {
  const { scrollIntoView, targetRef } = useScrollTrigger({ offset: 180 });

  const { currentRoute } = useRoutesContext();
  const router = useRouter();
  const location = router.getLocation();
  const currentAccessor = location?.hash?.replace('#', '');

  const { data: assets, isLoading } = useAssets();
  const { searchResults: riskRecord, isSearching, isError, handleSearch, error } = useSearch();

  const [currentTarget, setCurrentTarget] = useState(null);

  const eid =
    !isUndefined(currentRoute?.params?.eid) && !isEmpty(currentRoute?.params?.eid) ? currentRoute?.params?.eid : '';

  const hasResults = !isEmpty(riskRecord);

  /**
   * If an EID is passed in the URL, fetch the risk record.
   */
  useEffect(() => {
    let previousEid = null;

    if (isError && error?.response?.status === 404) {
      const pathPieces = error?.config?.headers?.['x-api-endpoint']?.split('/');
      previousEid = pathPieces[pathPieces.length - 1];
    }

    if (!isEmpty(eid) && previousEid !== eid) {
      handleSearch({ eid });
    }
  }, [eid, handleSearch, isError, error]);

  /**
   * sets the current target to the current accessor
   */
  useLayoutEffect(() => {
    if (
      !isEmpty(eid) &&
      !isLoading &&
      !isSearching &&
      hasResults &&
      !isNil(currentAccessor) &&
      currentAccessor !== currentTarget
    ) {
      setCurrentTarget(currentAccessor);
    }
  }, [eid, isLoading, isSearching, hasResults, currentAccessor]);

  /**
   * scrolls to the current target when the current target changes
   */
  useLayoutEffect(() => {
    scrollIntoView();
  }, [currentTarget, targetRef]);

  /**
   * check if the EID has a DOB attribute
   */
  const hasDOB = useMemo(() => {
    return riskRecord?.ireRiskRecord?.remediationFindings?.find((attribute) => {
      return attribute.attributeType === 'dob';
    });
  }, [riskRecord?.ireRiskRecord?.remediationFindings]);

  /**
   * check if the EID has an SSN attribute
   */
  const hasSSN = useMemo(() => {
    return riskRecord?.ireRiskRecord?.remediationFindings?.find((attribute) => {
      return attribute.attributeType === 'ssn';
    });
  }, [riskRecord?.ireRiskRecord?.remediationFindings]);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/EidSearch.jsx">
      <Layout
        assets={assets}
        currentAccessor={currentAccessor}
        currentTarget={currentTarget}
        eid={eid}
        handleSearch={handleSearch}
        hasDOB={hasDOB}
        hasResults={hasResults}
        hasSSN={hasSSN}
        isLoading={isLoading}
        isSearching={isSearching}
        riskRecord={riskRecord}
        setCurrentTarget={setCurrentTarget}
        targetRef={targetRef}
      />
    </ErrorHandler>
  );
};
